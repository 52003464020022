import { gql } from "apollo-boost";

const GET_COURSE = gql`
	query Assistant($courseId: Int!) {
        request {
            grassistant {
                  assistantGetRoedeCourse(courseId: $courseId) {
                    courseId
                    name
										autoName
                    active
                    meetingStartTime
                    meetingEndTime
                    address
                    meetingWeekday
                    courseTypeName
                    instructorId
                    maxNumParticipants
                    forSaleText
                    freeSpotsAsTextInternal
                  }
            }
        }
	}
`

export default GET_COURSE;
